jQuery(window).on('load', function($){
});

jQuery(document).ready(function($){
	initAnchors();
});

// initialize smooth anchor links
function initAnchors() {
	new SmoothScroll({
		anchorLinks: 'a.smooth-scroll',
		extraOffset: 0,
		wheelBehavior: 'none'
	});
}